<template>
  <div id="app" @mousemove="isDon" @click="isDon">
    <router-view class="mainPart" />
  </div>
</template>

<style>
html {
  font-size: calc(100 / 1920 * 100vw) !important;
}
body {
  font-size: 32px;
}
</style>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      locale: zhCN,
      timmer: null,
    }
  },
  computed: {},
  mounted() {},
  watch: {
    $route(to, from) {
      if (from.path === '/') {
        this.isDon()
      } else {
        clearTimeout(this.timmer)
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/' })
      clearTimeout(this.timmer)
    },
    isDon() {
      const path = ['/']
      if (!path.includes(this.$route.path)) {
        clearTimeout(this.timmer)
        this.init()
      }
    },
    init() {
      this.timmer = setTimeout(() => {
        // this.goBack()
        if (this.$route.path !== '/home') {
          // toastService.showWarnMessage('长时间无操作，退出登录。')
        }
      }, 50000)
    },
  },
}
</script>
<style lang="less" scoped>
// 1、分组子菜单（标题）

#app {
  font-weight: 700;
}
// #app {
//   height: 1920px;
//   width: 1080px;
// }
</style>
