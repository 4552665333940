import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import _ from 'lodash'
import 'ant-design-vue/dist/antd.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import moment from 'moment';  //直接引入moment.js
// import vConsole from '@/js/vconsole.js'
import {
  apiGet,
  apiPost,
  apiPut
} from "@/request/api.js";
import mainjs from '@/js/main.js'
import {
  DownloadUrl,
  url,
  ServerBaseUrl
} from './request/config.js'; //全局配置
Vue.prototype.moment = moment;  //在原型上添加moment，this.moment使用即可
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
Vue.use(Antd)

Vue.prototype.$get = apiGet
Vue.prototype.$post = apiPost
Vue.prototype.$url = url;
Vue.prototype.$ServerBaseUrl = ServerBaseUrl;
Vue.prototype.$DownloadUrl = DownloadUrl;
Vue.prototype._ = _
Vue.prototype.$mainjs = mainjs

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')