import { Get, Post, Delete, Put } from "./http";
import {
  ServerBaseUrl
} from '../request/config.js'; //全局配置
const url = ServerBaseUrl;
// const url = "/entrance";


export const apiPost = (interfaceP, paramet1) =>
  Post(url + interfaceP, paramet1);
export const apiGet = (interfaceG, paramet2) => Get(url + interfaceG, paramet2);
export const apiDelete = (interfaceD, paramet3) =>
  Delete(url + interfaceD, paramet3);
export const apiPut = (interfacePu, paramet4) =>
  Put(url + interfacePu, paramet4);


