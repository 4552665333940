import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 用catch函数的回调 解决router引起的报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: function() {
      return import('../views/home/index')
    },
  },
  // 管理页面
  {
    path: '/administration',
    name: 'administration',
    // component: Home,
    component: function() {
      return import('../views/administration/index')
    },
  },
]
const scrollBehavior = function(to, from, savedPosition) {
  // savedPosition 会在你使用浏览器前进或后退按钮时候生效
  // 这个跟你使用 router.go() 或 router.back() 效果一致
  // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
  if (savedPosition) {
    return savedPosition
  } else {
    // 如果不是通过上述行为切换组件，就会让页面回到顶部
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  scrollBehavior,
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
