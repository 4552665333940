import axios from 'axios'
import store from '../store/index'
// import PubSub from "pubsub-js";
// import router from "../router";
// import { Notification } from 'ant-design-vue';
// 环境的切换
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '' // 开发环境
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = '' // 调试环境
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '' // 生产环境
}
axios.defaults.timeout = 100000
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data'

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = this.$store.state.Authorization
    // token && (config.headers.Authorization = token)
    // return config
    store.state.loadding = true
    if (sessionStorage.getItem('Authorization')) {
      config.headers.token = sessionStorage.getItem('Authorization')
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    store.state.loadding = false
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    try {
      store.state.loadding = false
      if (error.response.status) {
        switch (error.response.status) {
          // 400: 请求出错
          // 请求的参数有问题
          case 400:
            return Promise.reject(error.response)
            break
          // 401: 未登录
          // 未登录则跳转登录页面，并携带当前页面的路径
          // 在登录成功后返回当前页面，这一步需要在登录页操作。
          case 401:
          // 403 token过期
          // 登录过期对用户进行提示
          // 清除本地token和清空vuex中token对象
          // 跳转登录页面
          case 403:
            // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
            break
          // 其他错误，直接抛出错误提示
          default:
            // Notification["error"]({
            //   message: "Notification Title",
            //   description:
            //     "This is the content of the notification. This is the content of the notification. This is the content of the notification."
            // });
            return Promise.reject(error.response)
        }
      }
    } catch (error) {}
  }
)

/*以下四个方法用于完成登录功能之前测试使用*/
/** get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        reject(err.data)
      })
  })
  // });
}
/** post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/** put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, {
        ...params,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/** delete 方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        data: {
          params,
        },
        // headers: { 'Authorization': "Bearer " + res.data.token }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/*完成登录功能时开放*/

// /**
//  * get方法，对应get请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function Get (url, params) {
//   return new Promise((resolve, reject) => {
//     axios.get(url, params)
//       .then(res => {
//         resolve(res.data)
//       })
//       .catch(err => {
//         reject(err.data)
//       })
//   })
// }
// /**
//  * post方法，对应post请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function Post (url, params) {
//   return new Promise((resolve, reject) => {
//     axios.post(url, params)
//       .then(res => {
//         resolve(res.data)
//       })
//       .catch(err => {
//         reject(err.data)
//       })
//   })
// }
// /**
//  * put方法，对应put请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function Put (url, params) {
//   return new Promise((resolve, reject) => {
//     axios.put(url, params)
//       .then(res => {
//         resolve(res.data)
//       })
//       .catch(err => {
//         reject(err.data)
//       })
//   })
// }
// /**
//  * delete 方法，对应delete请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
// export function Delete (url, params) {
//   return new Promise((resolve, reject) => {
//     axios.delete(url, params)
//       .then(res => {
//         resolve(res.data)
//       })
//       .catch(err => {
//         reject(err.data)
//       })
//   })
// }
