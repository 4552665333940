import {
  apiGet,
  apiPost,
  apiPut
} from "@/request/api.js";

function getUserType() {
  apiGet
    (`/api/gc_trade_center/user_ext_info/me`).then((res) => {
      // return res.data
      auth = res.data.userType
    });
}
let auth = null

let mian = {
  getType() {
    return new Promise((resolve, reject) => {
      apiGet(`/api/gc_trade_center/user_ext_info/me`).then((res) => {
        // return res.data
        resolve(res.data.userType)
      });
    }
    )
    // return
    // await getUserType()
    // return auth
  }
}


export default mian