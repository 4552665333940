const ServerBaseUrl = "https://yxzs.wchuu.top/api"; //正式服务器
// const ServerBaseUrl = "http://43.138.37.45:8082/api"; //测试服务器
// const ServerBaseUrl = "/entrance";
// const ServerBaseUrl = "http://192.168.101.107:9000";

const ViewImgUrl = ServerBaseUrl + '/api/file/preview/';

function url(url) {
	return ServerBaseUrl + '/api/file/preview/' + url;
}

function DownloadUrl (url) {
	return ServerBaseUrl + '/api/file/download/' + url;
}


export {
	ServerBaseUrl,
	url,
	DownloadUrl
}
